module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rijad Husic","short_name":"Rijad Husic","description":"This is a portfolio page of Rijad Husic and it's made with Gatsby and Sanity.io. ","lang":"en","display":"standalone","icon":"src/images/page-icon.png","cache_busting_mode":"none","start_url":"/","background_color":"#F3F4F6","theme_color":"#2563EB","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/about/","/projects/*","/project/*","/blog/","/blog/*","/contact/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-XEMC02QM3N"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
